<template>
  <div id="app">
    <button class="back-button gun-page-button" @click="goBack">{{ $t("btn_back") }}</button>

    <img :src="gunImage" @click="playSound" class="fullscreen-image" />
  </div>
</template>

<script>
import { APP_CONFIG } from "@/constants";
import gunImage from "@/assets/revolver.jpg";
import gunSound from "@/assets/gunshot2.mp3";
import misFireSound from "@/assets/jangjun.mp3";

export default {
  props: ["lang"],
  data() {
    return {
      gunImage,
      gunSound,
      misFireSound,
    };
  },
  methods: {
    goBack() {
      this.$router.push(`/${this.$i18n.locale}`);
    },

    playSound() {
      let sound;
      if (Math.random() < 1 / 6) {
        sound = new Audio(this.gunSound);
      } else {
        sound = new Audio(this.misFireSound);
      }
      sound.play();
    },
  },

  created() {
    this.$i18n.locale = this.lang || APP_CONFIG.DEFAULT_LANGUAGE;
    if (!(this.$i18n.locale == "ko" || this.$i18n.locale == "en")) {
      this.$i18n.locale = APP_CONFIG.DEFAULT_LANGUAGE;
    }
  },
};
</script>

<style scoped>
/* .fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
} */

.gun-page-button {
  position: absolute;
  left: 5px;
  z-index: 2;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #dd4646;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.back-button {
  z-index: 2; /* 이 값을 적절히 조절해보세요. */
  /* 원하는 위치에 버튼을 배치하기 위해 필요한 CSS를 여기에 추가하세요. */
  position: fixed;
  max-width: 100px;
  top: 10px;
  right: 10px;
  z-index: 1000;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import StartPage from '@/components/StartPage';
import GunPage from '@/components/GunPage';



Vue.use(VueRouter)

const routes = [
  {
    path: '/gun',
    name: 'gun',
    component: GunPage
  },
  {
    path: '/',
    name: 'start',
    component: StartPage
  },
  {
    path: '/:lang',
    name: 'start',
    component: StartPage,
    props: true

  },
  {
    path: '/:lang/gun',
    name: 'gun',
    component: GunPage,
    props: true
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="language-selector">
    <label for="language">Language:</label>
    <select v-model="$i18n.locale" id="language">
      <option v-for="(label, code) in languages" :key="code" :value="code">
        {{ label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languages: {
        en: "English",
        ko: "한국어",
        // 'es': 'Español',
        // 'ja': '日本語',
        // 'zh': '中文',
        // 'vi': 'Tiếng Việt',
        // 'id': 'Bahasa Indonesia',
        // 'it': 'Italiano',
        // 'th': 'ภาษาไทย'
      }, // 사용자 친화적인 언어 명칭
    };
  },
};
</script>

<style scoped>
.language-selector {
  font-family: "Arial", sans-serif;
  background-color: #f3f3f3;
  padding: 10px;
}

label {
  margin-right: 10px;
}

select {
  padding: 5px;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
</style>

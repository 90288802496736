import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n); //Vue에게 i18n 을 사용하겠다고 명시

const messages = {
  ko: {
    btn_start: "시작",
    btn_back: "뒤로가기",
    description_title: "초 간단 설명",
    warnings0: "친구와 번갈아가면서 총을 쏘세요",
    warnings1: "1/6 확률로 총 소리가 납니다",
    warnings2: "총소리가 나면 지게 됩니다",
    warnings3: "귀에 너무 가까이 대지 마세요",
  },
  en: {
    btn_start: "Start",
    btn_back: "Go Back",
    description_title: "Brief explanation",
    warnings0: "Take turns shooting the gun with your friend",
    warnings1: "There's a 1/6 chance of the gun making a sound",
    warnings2: "If the gun makes a sound, you lose",
    warnings3: "Don't hold it too close to your ear",
  },
};

export const i18n = new VueI18n({
  locale: "ko", // 기본 언어
  fallbackLocale: "en", // 기본 언어 표시에 문제가 있을 경우 대체할 언어
  messages,
});

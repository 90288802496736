<template>
  <div :style="{ backgroundColor: bgColor }">
    <h2 class="my-text-title">Russian Roulette</h2>
    <div class="image-section">
      <img :src="logo" alt="My Image" />
    </div>

    <button class="my-button mb-3" @click="start">{{ $t("btn_start") }}</button>

    <p class="my-text-description">{{ $t("description_title") }}</p>
    <p class="my-text-color">{{ $t("warnings0") }}<br />{{ $t("warnings1") }}<br />{{ $t("warnings2") }}</p>
    <language-selector />
  </div>
</template>

<script>
import { APP_CONFIG } from "@/constants";
import logo from "@/assets/revolver_main.jpg";
import LanguageSelector from "./LanguageSelector.vue";

export default {
  props: ["lang"],
  components: {
    LanguageSelector,
  },
  data() {
    return {
      logo,
      bgColor: "#2c535a",
    };
  },
  methods: {
    start() {
      this.$router.push(`/${this.$i18n.locale}/gun`);
    },
    setLanguage() {
      if (navigator.language.startsWith("ko")) {
        return "ko";
      } else if (navigator.language.startsWith("en")) {
        return "en";
      } else {
        return "en"; // 기본값으로 'en' 설정
      }
    },
  },
  created() {
    this.$i18n.locale = this.lang || this.setLanguage();
    if (!(this.$i18n.locale == "ko" || this.$i18n.locale == "en")) {
      this.$i18n.locale = APP_CONFIG.DEFAULT_LANGUAGE;
    }
  },
};
</script>

<style scoped>
.my-button {
  margin-top: 15px;
  margin-bottom: 10px;
  z-index: 2;
  padding: 10px 20px;
  font-size: 30px;
  color: #fff;
  background-color: #9b2828;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.image-section img {
  width: 70%;
  max-width: 330px;
  height: auto;
}

.my-text-description {
  margin-top: 20px;
  padding: 0px;
  margin-bottom: 0px;
  color: rgb(231, 231, 231);
  font-size: 20px;
}
.my-text-color {
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgb(231, 231, 231);
  font-size: 17px;
}
.my-text-title {
  color: rgb(255, 255, 255);
  font-size: 35px;
  padding: 10px;
}

/* ```css
.back-button {
  position: absolute;
  z-index: 2;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
```

2. 그림자 효과 버튼:

```css
.back-button {
  position: absolute;
  z-index: 2;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
```

3. 투명 배경 버튼:

```css
.back-button {
  position: absolute;
  z-index: 2;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  background-color: transparent;
  border: 2px solid #333;
  border-radius: 5px;
  cursor: pointer;
}
``` */
</style>
